import React from 'react';
import { CheckIcon } from '../../utils/IconUtils';
import bgImage from '../../static/div-framer.png'

import styles from './PricingCard.module.scss';

const PricingCard = ({ data, checked }) => {

    return (
        <div className={styles.pricingCardContainer}>
            {
                data.popular
                &&
                <div className={styles.bgImageContainer}>
                    <img style={{ width: "100%", height: "100%" }} src={bgImage} alt="" />
                </div>
            }
            <div style={{ position: "relative", zIndex: "2" }}>
                {data.icon}
            </div>
            <div className={styles.title}>{data.title}</div>
            <div className={styles.price}>{checked ? data.yearly_billed : data.mountly_billed}</div>
            <div className={styles.contentContainer}>
                {
                    data.info_data.map(val => (
                        <div className={styles.contentItem}>
                            <CheckIcon />
                            <span>{val}</span>
                        </div>
                    ))
                }
            </div>
            <div className={styles.buttonContainer}>
                <button style={{ color: data.popular ? "#111111" : "#fff", backgroundColor: data.popular ? "#fff" : "#111111"}}>
                    {data.button_text}
                </button>
            </div>
        </div>
    )
}

export default PricingCard