import React from 'react';
import { motion } from "framer-motion";
import BlogCard from '../../components/blogCard/BlogCard';
import blogImage from '../../static/blog-img.webp';

import styles from './BlogPage.module.scss';

const BlogPage = () => {

    const blogData = {
        items: [
            {
                log_cover_img: blogImage,
                blog_id: 1,
                blog_tags: "Artificial Intelligence,Marketing ,Digital Marketing",
                blog_title: "AI: The Game-Changer in Content Creation and Marketing Industry",
                category_id: 3,
                content: "",
                publish_status: true,
                published_at: "2024-01-17T15:30:07.530240"
            },
            {
                log_cover_img: blogImage,
                blog_id: 2,
                blog_tags: "Artificial Intelligence,Marketing ,Digital Marketing",
                blog_title: "AI: The Game-Changer in Content Creation and Marketing Industry",
                category_id: 3,
                content: "",
                publish_status: true,
                published_at: "2024-01-17T15:30:07.530240"
            },
            {
                log_cover_img: blogImage,
                blog_id: 3,
                blog_tags: "Artificial Intelligence,Marketing ,Digital Marketing",
                blog_title: "AI: The Game-Changer in Content Creation and Marketing Industry",
                category_id: 3,
                content: "",
                publish_status: true,
                published_at: "2024-01-17T15:30:07.530240"
            },
            {
                log_cover_img: blogImage,
                blog_id: 4,
                blog_tags: "Artificial Intelligence,Marketing ,Digital Marketing",
                blog_title: "AI: The Game-Changer in Content Creation and Marketing Industry",
                category_id: 3,
                content: "",
                publish_status: true,
                published_at: "2024-01-17T15:30:07.530240"
            },
            {
                log_cover_img: blogImage,
                blog_id: 5,
                blog_tags: "Artificial Intelligence,Marketing ,Digital Marketing",
                blog_title: "AI: The Game-Changer in Content Creation and Marketing Industry",
                category_id: 3,
                content: "",
                publish_status: true,
                published_at: "2024-01-17T15:30:07.530240"
            },
        ]
    }

    return (
        <div className={styles.blogPageContainer}>
            <div className={styles.blogTitleContainer}>
                <div className={styles.blogTitleMainContainer}>
                    <motion.h2
                        initial={{ y: -80, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: 0.6 }}
                    >
                        Kubrix Video Blog
                    </motion.h2>
                    <motion.span
                        initial={{ y: 80, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: 0.6 }}
                    >
                        We are passionate about sharing valuable insights, industry trends, and expert perspectives to keep you informed and inspired.
                    </motion.span>
                </div>
            </div>
            <div className={styles.bgContainer}>
                <div className={styles.bgMainContainer}>

                </div>
            </div>
            <div className={styles.blogCardContainer}>
                <div className={styles.blogCardMainContainer}>
                    {
                        blogData.items.map((val) => (
                            <BlogCard key={val.blog_id} data={val} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default BlogPage;