import React, { useState } from 'react';
import PricingCard from '../../components/pricingCard/PricingCard';
import { motion } from "framer-motion";
import Switch from 'react-ios-switch';

import styles from './PricingPage.module.scss';
import { HiAiIcon, InfiniteIcon, ProIcon } from '../../utils/IconUtils';

const PricingPage = () => {

    const [checked, setChecked] = useState(true);
    const pricingPage = [
        {
            id: 0,
            title: "HiAI",
            mountly_billed: "Free",
            yearly_billed: "Free",
            info_data: [
                "3 Video Generation Monthly",
                "Limited AI Quality",
                "With Watemark",
                "4K Quality",
            ],
            button_text: "Get Started",
            icon: <HiAiIcon />
        },
        {
            id: 1,
            title: "Pro",
            mountly_billed: "$8 / mo",
            yearly_billed: "$6 / mo",
            info_data: [
                "25 Video Generation Monthly",
                "High AI Quality",
                "No Watemark",
                "4K Quality",
            ],
            button_text: "Get Started",
            icon: <ProIcon />
        },
        {
            id: 2,
            title: "Growth",
            mountly_billed: "$29 / mo",
            yearly_billed: "$23 / mo",
            info_data: [
                "125 Video Generation",
                "High AI Quality",
                "No Watemark",
                "4K Quality",
            ],
            button_text: "Get Started",
            icon: <InfiniteIcon />,
            popular: true
        },
        {
            id: 3,
            title: "D-Day",
            mountly_billed: "$86 / mo",
            yearly_billed: "$66 / mo",
            info_data: [
                "1000 Video Generation",
                "High AI Quality",
                "No Watemark",
                "4K Quality",
            ],
            button_text: "Contact Us",
            icon: <InfiniteIcon />
        }
    ]
    return (
        <div className={styles.pricingPageContainer}>
            <div className={styles.pricingTitleContainer}>
                <div className={styles.pricingTitleMainContainer}>
                    <motion.h2
                        initial={{ y: -80, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: 0.6 }}
                    >
                        Get the Features You Want at a Price You'll Love
                    </motion.h2>
                </div>
            </div>
            <div className={styles.switchContainer}>
                <motion.div 
                    initial={{ x: -80, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ ease: "easeOut", duration: 0.6 }}
                    className={styles.leftTextContainer}
                >
                    <span style={{ color: checked ? "#989898" : "#EEEEEE"}}>
                        Billed monthly
                    </span>
                </motion.div>
                <Switch
                    checked={checked}
                    onChange={checked => setChecked(checked)}
                    onColor="#A7B22C"
                    offColor="#525252"
                />
                <div className={styles.rightTextContainer}>
                    <motion.span 
                        initial={{ x: 80, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: 0.6 }}
                        style={{ color: checked ? "#EEEEEE" : "#989898"}} 
                        className={styles.text}
                    >
                        Billed yearly
                    </motion.span>
                    <motion.span 
                        initial={{ x: 80, opacity: 0 }}
                        animate={{ x: 0, opacity: 1 }}
                        transition={{ ease: "easeOut", duration: 0.6 }}
                        className={styles.info}
                    >
                        (Save up to 25%)
                    </motion.span>
                </div>
            </div>
            <div className={styles.cardContainer}>
                <div className={styles.cardMainContainer}>
                    {
                        pricingPage.map(val => (
                            <PricingCard key={val.id} data={val} checked={checked} />
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default PricingPage;