import React from 'react';
import backgroundVideo from '../../static/videos/homepage-video.mov';
import backgroundVideoWebm from '../../static/videos/homepage-video.webm';

import styles from './BackgroundVideo.module.scss';

const BackgroundVideo = () => {
    return (
        <div className={styles.backgroundVideoCont}>
            <video autoPlay muted loop id="myVideo2" preload="auto" playsInline>
                <source src={backgroundVideoWebm} type="video/webm" />
                <source src={backgroundVideo} type="video/mp4" />
            </video>
        </div>
    )
}

export default BackgroundVideo