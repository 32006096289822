import React from 'react';

import styles from './Footer.module.scss'

const Footer = () => {

    return (
        <div className={styles.footerContainer}>
            <div className={styles.footerMainContainer}>
                <div className={styles.footerItemContainer}>
                    <span>
                        Kubrix ai
                    </span>
                    <span>
                        © 2024
                    </span>
                </div>
                <div className={styles.footerItemContainer}>
                    <h4>
                        Follow
                    </h4>
                    <span>
                        Github
                    </span>
                    <span>
                        Twitter
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Footer