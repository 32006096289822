import React, { useState } from 'react';
import backgroundVideo from '../../static/videos/homepage-video.mov';
import Tilt from "react-parallax-tilt";

import styles from './VideoCard.module.scss';

const VideoCard = ({ url, content }) => {

    const [isHover, setIsHover] = useState(false);

    const stopMovie = (e) => {
        e.target.pause();
        setIsHover(false);
      }
      
    const playMovie = (e) => {
        e.target.play();
        setIsHover(true);
      }

    return (
        <Tilt 
            glareEnable={true} 
            tiltMaxAngleX={10} 
            tiltMaxAngleY={10} 
            perspective={1200} 
            // style={{width: "375px"}}
            // glareColor={"rgba(255,0,0,0.1)"}
            glareColor={"rgba(245, 243, 193, 0.1)"}
            className={styles.videoCardContainer}
            style={{background: isHover ? "#191919" : "transparent"}}
        >
            <div className={styles.contentContainer}>
                <div className={styles.videoContainer}>
                    <video 
                        onMouseOver={(e) => playMovie(e)}
                        onMouseOut={(e) => stopMovie(e)}
                        style={{width: "100%", height: "100%"}} 
                        muted 
                        id="myVideo2"
                    >
                        <source src={url} type="video/mp4" />
                    </video>
                </div>
                <div className={styles.textContainer}>
                    <span>
                        {content}
                    </span>
                </div>
            </div>
        </Tilt>
    )
}

export default VideoCard;