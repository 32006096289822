import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SANLayout from './SANLayout';
import HomePage from './pages/homePage/HomePage';
import PricingPage from './pages/pricingPage/PricingPage';
import BlogPage from './pages/blogPage/BlogPage';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SANLayout/>}>
          <Route path="/" element={<HomePage/>} />
          <Route path="/pricing" element={<PricingPage/>} />
          <Route path="/blog" element={<BlogPage/>} />
        </Route>
      </Routes>
    </Router>
  )
}

export default AppRoutes;