import React from 'react';

import styles from './BlogCard.module.scss';
import { DateIcon } from '../../utils/IconUtils';

const BlogCard = ({ data }) => {

    const convertDate = (dateString) => {
        const date = new Date(dateString);

        // Gün, ay ve yıl bilgilerini al
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Aylar 0'dan başlar
        const year = date.getFullYear();
            
        // Saat ve dakika bilgilerini al
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
            
        // İstenilen formata dönüştür
        return `${day}.${month}.${year} ${hours}:${minutes}`;
    }

    return (
        <div className={styles.blogCardContainer}>
            <div className={styles.tagsContainer}>
                {
                    data.blog_tags.split(",").map(val => (
                        <div className={styles.tagItem}>
                            <span>{val}</span>
                        </div>
                    ))
                }
            </div>
            <div className={styles.titleContainer}>
                <span>{data.blog_title}</span>
            </div>
            <div className={styles.blogImageContainer}>
                <img src={data.log_cover_img} alt="" />
            </div>
            <div className={styles.dateContainer}>
                <DateIcon />
                <span>
                    {convertDate(data.published_at)}
                </span>
            </div>
        </div>
    )
}

export default BlogCard